
import {
  computed, defineComponent, PropType, Ref, toRefs,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import { ITestInfoGetResponseData as IQuizResult } from '@/services/api/lxp-quiz-test/types';
import { useQuizDetailedResult } from '@/domains/quiz/composables/useQuizDetailedResult';

import QuizAnswersProgressPlate from '@/components/quiz/QuizAnswer/QuizAnswersProgressPlate';
import QuizAnswersQuestion from '@/components/quiz/QuizAnswer/QuizAnswersQuestion';

export default defineComponent({
  name: 'QuizResultDetails',

  components: {
    QuizAnswersProgressPlate,
    QuizAnswersQuestion,
  },

  props: {
    testInfo: {
      type: Object as PropType<IQuizResult>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { testInfo } = toRefs(props);
    const route = useRoute();

    const playerSessionId: Ref<number> = computed(() => Number(route.params.playerSessionId));

    const {
      questions,
      correctAnswers,
      questionsCount,
      testThreshold,
      answersDisplayType,
      fetchQuizResults,
    } = useQuizDetailedResult({ quiz: testInfo, playerSessionId });

    const init = async () => {
      try {
        await fetchQuizResults();
      } catch (e) {
        // NOTE: console.error и нотификация есть в fetchQuizResults
        emit('error:results');
      }
    };

    init();

    return {
      questions,
      correctAnswers,
      questionsCount,
      testThreshold,
      answersDisplayType,
      fetchQuizResults,
    };
  },
});
