import transport from './transport';
import * as methods from '@/services/api/constants';

import {
  ITestInfoGetResponseData,
  ITestSessionAnswerFinishRequest,
  ITestSessionAnswerFinishResponseData,
  ITestSessionAnswerSkipRequest,
  ITestSessionAnswerSkipResponseData,
  ITestSessionAnswerUpdateRequest,
  ITestSessionCheckVersionResponseData,
  ITestSessionFinishRequest,
  ITestSessionFinishResponseData,
  ITestSessionPlayerResultsRequestResponseData,
  ITestSessionQuestionGetRequestQueryParams,
  ITestSessionQuestionGetResponseData,
  ITestSessionQuestionsResponseData,
  ITestSessionResultsRequest,
  ITestSessionResultsResponseData,
  ITestSessionRunRequestQueryParams,
  ITestSessionRunResponseData,
} from './types';
import { ITestSessionAnswerUpdateResponse } from '../lxp-multi-level-test/types';
import { PlayerSessionId } from '@/domains/common';

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testInfoGet
 */
export const testInfoGet = async (
  { playerSessionId }: { playerSessionId: number },
): Promise<ITestInfoGetResponseData> => {
  const response = await transport({
    url: `/v3/tests/${playerSessionId}/info`,
    method: methods.HTTP_GET,
  });

  response.results = response.results ?? {};

  return response;
};

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionRun
 */
export const testSessionRun = ({
  params: {
    playerSessionId,
  },
}: {
  params: ITestSessionRunRequestQueryParams,
}): Promise<ITestSessionRunResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/run`,
  method: methods.HTTP_POST,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionQuestionsGet
 */
export const testSessionQuestionsGet = ({
  params: {
    playerSessionId,
  },
}: {
  params: ITestSessionRunRequestQueryParams,
}): Promise<ITestSessionQuestionsResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/questions`,
  method: methods.HTTP_GET,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionQuestionGet
 */
export const testSessionQuestionGet = ({
  params: {
    playerSessionId,
    questionId,
  },
}: {
  params: ITestSessionQuestionGetRequestQueryParams,
}): Promise<ITestSessionQuestionGetResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/questions/${questionId}`,
  method: methods.HTTP_GET,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionAnswerUpdate
 */
export const testSessionAnswerUpdate = ({
  params: {
    playerSessionId,
    answerId,
  },
  payload,
}: ITestSessionAnswerUpdateRequest): Promise<ITestSessionAnswerUpdateResponse> => transport({
  url: `/v3/tests/${playerSessionId}/answers/${answerId}`,
  method: methods.HTTP_PUT,
  data: payload,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionAnswerFinish
 */
export const testSessionAnswerFinish = ({
  params: {
    playerSessionId,
    answerId,
  },
}: ITestSessionAnswerFinishRequest): Promise<ITestSessionAnswerFinishResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/answers/${answerId}/finish`,
  method: methods.HTTP_POST,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionAnswerSkip
 */
export const testSessionAnswerSkip = ({
  params: {
    playerSessionId,
    answerId,
  },
}: ITestSessionAnswerSkipRequest): Promise<ITestSessionAnswerSkipResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/answers/${answerId}/skip`,
  method: methods.HTTP_POST,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionFinish
 */
export const testSessionFinish = ({
  params: {
    playerSessionId,
  },
}: ITestSessionFinishRequest): Promise<ITestSessionFinishResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/finish`,
  method: methods.HTTP_POST,
});

/**
 * @url https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2971402241#testSessionResults
 */
export const testSessionResults = ({
  params: {
    playerSessionId,
  },
}: ITestSessionResultsRequest): Promise<ITestSessionResultsResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/results`,
  method: methods.HTTP_GET,
});

export const testSessionPlayerResults = async (
  { playerSessionId }: { playerSessionId: PlayerSessionId },
): Promise<ITestSessionPlayerResultsRequestResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/player_results`,
  method: methods.HTTP_GET,
});

export const testSessionCheckVersion = (
  { playerSessionId }: { playerSessionId: PlayerSessionId },
): Promise<ITestSessionCheckVersionResponseData> => transport({
  url: `/v3/tests/${playerSessionId}/check_version`,
  method: methods.HTTP_GET,
});
