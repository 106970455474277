import { DateTimeISO8601, PlayerSessionStatus, UUID } from '../common';

export enum QuizQuestionType {
  POLY = 'PolyTypeQuestion',
  HASH = 'HashTypeQuestion',
  ORDER = 'OrderTypeQuestion',
  OPEN = 'OpenTypeQuestion',
}

/**
 * https://burning-heart.atlassian.net/wiki/spaces/LXP/pages/2950135821#Common
 */
export enum QuizAnswersDisplayType {
  NONE = 'answers_display_type_none',
  ALL = 'answers_display_type_all_questions',
  WRONG = 'answers_display_type_wrong_questions',
}

export enum QuizQuestionPolyTypeMode {
  MONO = 'mono',
  POLY = 'poly',
}

export enum QuizQuestionHashChoiceType {
  KEY = 'key',
  VALUE = 'value',
}

export type IQuizResult = {
  passed: boolean; // признак успешного прохождения теста
  correctAnswers: number; // количество правильных ответов
  testThreshold: number; // порог прохождения в процентах от 0 до 1
  answersDisplayType: QuizAnswersDisplayType; // режим отображения результатов
  percent: number;
  percentDifference: number;
};

export interface IQuiz {
  playerSessionStatus: PlayerSessionStatus;
  name: string;
  description: string | null;
  questionsCount: number; // количество вопросов в тесте
  testTimeout: number; // время для прохождения теста в минутах, по умолчанию 0 (если нет ограничений по времени)
  freeFlow: boolean; // признак свободного выбора вопросов
  results: IQuizResult | null;
  maxAttempts: number;
  attemptsCount: number;
  nextAttemptAt: DateTimeISO8601 | null;
}

export enum QuizQuestionStatus {
  CURRENT = 'current',
  CURRENT_FILLED = 'current_filled',
  FILLED = 'filled',
  EMPTY = 'empty',
  BLOCKED = 'blocked'
}

export enum QuizAnswerStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
}

export interface IQuizQuestion {
  id: number;
  order: number; // порядковый номер вопроса
  status: QuizQuestionStatus, // Статус вопроса, для отображения на странице
  answerStatus: QuizAnswerStatus; // Статус связанного ответа
  current: boolean; // флаг, является ли вопрос текущим
}

interface IQuizCurrentQuestionChoiceBase {
  id: number;
  title: string;
  payload: {};
}

export interface IQuizCurrentQuestionChoicePoly extends IQuizCurrentQuestionChoiceBase {
  payload: {
    imageUuids?: UUID[];
  };
}

export interface IQuizCurrentQuestionChoiceHash extends IQuizCurrentQuestionChoiceBase {
  payload: {
    type: QuizQuestionHashChoiceType;
  };
}

export interface IQuizCurrentQuestionChoiceOrder extends IQuizCurrentQuestionChoiceBase {
  payload: {};
}

export type IQuizCurrentQuestionChoice =
  | IQuizCurrentQuestionChoicePoly
  | IQuizCurrentQuestionChoiceHash
  | IQuizCurrentQuestionChoiceOrder;

export interface IQuizCurrentQuestionBase {
  id: number;
  title: string;
  imageUuids: UUID[];
  type: QuizQuestionType;
  payload: {};
  choices: IQuizCurrentQuestionChoiceBase[];
}

export interface IQuizCurrentQuestionPoly extends IQuizCurrentQuestionBase {
  type: QuizQuestionType.POLY;
  payload: {
    type: QuizQuestionPolyTypeMode;
  };
  choices: IQuizCurrentQuestionChoicePoly[];
}

export interface IQuizCurrentQuestionHash extends IQuizCurrentQuestionBase {
  type: QuizQuestionType.HASH;
  choices: IQuizCurrentQuestionChoiceHash[];
}

export interface IQuizCurrentQuestionOpen extends IQuizCurrentQuestionBase {
  type: QuizQuestionType.OPEN;
  choices: [];
  payload: {
    hint: string;
  };
}

export interface IQuizCurrentQuestionOrder extends IQuizCurrentQuestionBase {

}

export type IQuizCurrentQuestion =
  | IQuizCurrentQuestionPoly
  | IQuizCurrentQuestionHash
  | IQuizCurrentQuestionOpen
  | IQuizCurrentQuestionOrder;

export type IQuizCurrentAnswerResultPoly = {
  id: IQuizCurrentQuestionChoicePoly['id'],
  value: boolean
};

export type IQuizCurrentAnswerResultHash = {
  keyId: IQuizCurrentQuestionChoiceBase['id'],
  valueId: IQuizCurrentQuestionChoiceBase['id']
};
export type IQuizCurrentAnswerResult = IQuizCurrentAnswerResultPoly[] | IQuizCurrentAnswerResultHash[];

interface IQuizCurrentAnswerBase {
  id: number;
}

export interface IQuizCurrentAnswerPoly extends IQuizCurrentAnswerBase {
  result: IQuizCurrentAnswerResultPoly[];
}

export interface IQuizCurrentAnswerHash extends IQuizCurrentAnswerBase {
  result: IQuizCurrentAnswerResultHash[];
}

export interface IQuizCurrentAnswerOpen extends IQuizCurrentAnswerBase {
  result: [{
    value: string;
  }];
}

export interface IQuizCurrentAnswerOrder extends IQuizCurrentAnswerBase {
  result: {
    order: number;
    choiceId: number;
  }[];
}

export type IQuizCurrentAnswer =
|IQuizCurrentAnswerPoly
|IQuizCurrentAnswerHash
|IQuizCurrentAnswerOpen;

export interface IQuizAnswerResultHash {
  result: IQuizCurrentAnswerResultHash;
}

export interface IQuizAnswerResultPoly {
  result: Record<IQuizCurrentQuestionChoicePoly['id'], boolean>;
}

export type IQuizAnswerResult = IQuizAnswerResultHash | IQuizAnswerResultPoly;

/**
 *
 * QuizQuestionResult
 *
 */
export enum QuizQuestionResultStatus {
  CORRECT = 'correct',
  WRONG = 'wrong',
  SKIPPED = 'skipped',
}

export enum QuizAnswerResultStatus {
  ISCORRECT = 'is_correct',
  INCORRECT = 'incorrect',
  GENERAL = 'general',
}

/**
 * QuizQuestionResultChoice
 */
interface IQuizChoiceBase {
  id: number;
  title: string;
}

interface IQuizExtendedChoice extends IQuizChoiceBase {
  polyTypeChoicePayload: unknown;
  hashTypeChoicePayload: unknown;
  orderTypeChoicePayload: unknown;

}

export interface IQuizExtendedChoicePoly extends IQuizExtendedChoice {
  polyTypeChoicePayload: {
    imageUuids: UUID[];
    right: boolean;
  };
  hashTypeChoicePayload: null;
  orderTypeChoicePayload: null;
}

export interface IQuizExtendedChoiceHash extends IQuizExtendedChoice {
  polyTypeChoicePayload: null;
  hashTypeChoicePayload: {
    type: QuizQuestionHashChoiceType;
    comment: string | null;
  };
  orderTypeChoicePayload: null;
}

export interface IQuizExtendedChoiceOrder extends IQuizExtendedChoice {
  polyTypeChoicePayload: null;
  hashTypeChoicePayload: null;
  orderTypeChoicePayload: {
    order: number;
  };
}

export interface IQuizExtendedChoiceOpen extends IQuizExtendedChoice {
  polyTypeChoicePayload: null;
  hashTypeChoicePayload: null;
  orderTypeChoicePayload: null;
}

/**
 * QuizQuestionResultAnswer
 */

interface IQuizAnswerResultBase {
  id: number;
  title: string;
  correctStatus: QuizAnswerResultStatus;
  comment: string | null;
  polyTypeAnswerPayload: unknown;
  hashTypeAnswerPayload: unknown;
  openTypeAnswerPayload: unknown;
  orderTypeAnswerPayload: unknown;
}

export interface IQuizQuestionResultAnswerPoly extends IQuizAnswerResultBase {
  polyTypeAnswerPayload: {
    checked: boolean;
    imageUuids: UUID[];
  };
  hashTypeAnswerPayload: null;
  openTypeAnswerPayload: null;
  orderTypeAnswerPayload: null;
}

export interface IQuizQuestionResultAnswerHash extends IQuizAnswerResultBase {
  polyTypeAnswerPayload: null;
  hashTypeAnswerPayload: {
    type: QuizQuestionHashChoiceType;
    relatedIn: IQuizAnswerResultBase['id'];
  };
  openTypeAnswerPayload: null;
  orderTypeAnswerPayload: null;
}

export interface IQuizQuestionResultAnswerOrderResult {
  choiceId: IQuizCurrentQuestionChoiceOrder['id'];
  order: number;
}

export interface IQuizQuestionResultAnswerOpen extends IQuizAnswerResultBase {
  polyTypeAnswerPayload: null;
  hashTypeAnswerPayload: null;
  openTypeAnswerPayload: {
    userAnswer: string;
  };
  orderTypeAnswerPayload: null;
}

export interface IQuizQuestionResultAnswerOrder extends IQuizAnswerResultBase {
  polyTypeAnswerPayload: null;
  hashTypeAnswerPayload: null;
  openTypeAnswerPayload: null;
  orderTypeAnswerPayload: {
    order: number;
  };
}

/**
 * QuizQuestionResult
 */
interface IQuizQuestionResultBase {
  id: number;
  title: string;
  comment: string | null;
  imageUuids: UUID[];
  type: QuizQuestionType;
  status: QuizQuestionResultStatus;
  polyTypeQuestionPayload: unknown;
  hashTypeQuestionPayload: unknown;
  extendedChoices: unknown[];
  answeredChoices: unknown;
}

export interface IQuizQuestionResultPoly extends IQuizQuestionResultBase {
  type: QuizQuestionType.POLY;
  polyTypeQuestionPayload: {
    type: QuizQuestionPolyTypeMode;
  };
  hashTypeQuestionPayload: null;
  extendedChoices: IQuizExtendedChoicePoly[];
  answeredChoices: IQuizQuestionResultAnswerPoly[];
}

export interface IQuizQuestionResultHash extends IQuizQuestionResultBase {
  type: QuizQuestionType.HASH;
  polyTypeQuestionPayload: null;
  hashTypeQuestionPayload: {
    rightRelation: [IQuizChoiceBase['id'], IQuizChoiceBase['id']][];
  };
  extendedChoices: IQuizExtendedChoiceHash[];
  answeredChoices: IQuizQuestionResultAnswerHash[];
}

export interface IQuizQuestionResultOrder extends IQuizQuestionResultBase {
  type: QuizQuestionType.ORDER;
  polyTypeQuestionPayload: null;
  hashTypeQuestionPayload: null;
  extendedChoices: IQuizExtendedChoiceOrder[];
  answeredChoices: IQuizQuestionResultAnswerOrder[];
}

export interface IQuizQuestionResultOpen extends IQuizQuestionResultBase {
  type: QuizQuestionType.OPEN;
  polyTypeQuestionPayload: null;
  hashTypeQuestionPayload: null;
  extendedChoices: IQuizExtendedChoiceOpen[];
  answeredChoices: IQuizQuestionResultAnswerOpen[];
}

export type IQuizQuestionResult = IQuizQuestionResultPoly | IQuizQuestionResultHash
| IQuizQuestionResultOpen | IQuizQuestionResultOrder;

export enum IQuizStep {
  INTRO = 'intro',
  PLAYER = 'player',
  RESULT = 'result',
  ANSWERS = 'answers',
}
